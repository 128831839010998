import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useNavigation = (globalLocale) => {
  const data = useStaticQuery(graphql`
    query {
      navs: allNavigationYaml {
        nodes {
          de
          en
        }
      }
    }
  `);

  const enKeys = {};
  const deKeys = {};

  data.navs.nodes.forEach((nav) => {
    deKeys[nav.de] = nav.en;
    enKeys[nav.en] = nav.de;
  });

  const getLink = (link, locale = 'de') => {
    let linkLocale = globalLocale || locale;

    if (deKeys[link] && linkLocale === 'en') {
      return deKeys[link];
    }

    if (enKeys[link] && linkLocale === 'de') {
      return enKeys[link];
    }

    if (link.includes('/blog/') && linkLocale === 'en') {
      return deKeys['/blog/'];
    }

    return link;
  };

  return { getLink };
};

export default useNavigation;
