const isInternalLink = (link) => {
  const whiteList = [
    'plusmeta.de',
    'www.plusmeta.de',
    'blog.plusmeta.de',
    'help.plusmeta.de',
    'iirds-ot.plusmeta.de',
  ];

  let isWhiteListed = false;

  whiteList.forEach((domain) => {
    if (link.includes(domain)) {
      isWhiteListed = true;
    }
  });

  if (isWhiteListed) {
    return isWhiteListed;
  }

  return !(new RegExp(/^[a-zA-Z]+:(?!(\/\/(www\.)?plusmeta.de))/)).test(link);
};

export default isInternalLink;
