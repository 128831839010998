import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: 'h-6 w-6',
};

const GoogleIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68,0c4.239,0 7.68,3.441 7.68,7.68c0,4.239 -3.441,7.68 -7.68,7.68c-4.239,-0 -7.68,-3.441 -7.68,-7.68c0,-4.239 3.441,-7.68 7.68,-7.68Zm5.694,7.145l-5.447,0l-0,1.622l3.867,0c-0.196,2.264 -2.079,3.232 -3.862,3.232c-2.275,0 -4.271,-1.794 -4.271,-4.319c-0,-2.436 1.901,-4.319 4.277,-4.319c1.836,-0 2.912,1.17 2.912,1.17l1.128,-1.176c0,-0 -1.449,-1.616 -4.099,-1.616c-3.374,-0 -5.983,2.852 -5.983,5.941c0,3 2.454,5.941 6.072,5.941c3.179,0 5.496,-2.18 5.496,-5.4c-0,-0.684 -0.09,-1.076 -0.09,-1.076Z"
      fill="currentColor"
    />
  </svg>
);

GoogleIcon.propTypes = propTypes;
GoogleIcon.defaultProps = defaultProps;

export default React.memo(GoogleIcon);
