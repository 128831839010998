import React from 'react';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import Link from '../Link';

const Nav = ({ children, className }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

const NavLink = ({ children, to, title }) => {
  return (
    <div className="group flex items-center relative">
      <Link
        href={to}
        className="typo-intro py-1 no-underline hover:text-indigo-500 mr-1"
      >
        {title}
      </Link>
      {children}
    </div>
  );
};

const NavDropdown = ({ children }) => {
  return (
    <>
      <ChevronDownIcon className="text-gray-600 h-6 w-6" />
      <ul className="bg-white shadow-xl px-4 py-2 invisible min-w-[200px] opacity-0 group-hover:visible group-hover:opacity-100 absolute top-[100%] left-0">
        {children}
      </ul>
    </>
  );
};

const NavDropdownLink = ({ children, to }) => {
  return (
    <li>
      <Link href={to} className="typo-body no-underline hover:underline hover:text-indigo-500">
        {children}
      </Link>
    </li>
  );
};

Nav.Link = NavLink;
Nav.Dropdown = NavDropdown;
Nav.DropdownLink = NavDropdownLink;

export default Nav;
