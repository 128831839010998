import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: 'h-6 w-6',
};

const YoutubeIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
    >
        <path
            d="M7.68,0c4.239,0 7.68,3.441 7.68,7.68c0,4.239 -3.441,7.68 -7.68,7.68c-4.239,-0 -7.68,-3.441 -7.68,-7.68c0,-4.239 3.441,-7.68 7.68,-7.68Zm5.277,5.014c-0.138,-0.497 -0.458,-0.817 -0.955,-0.955c-0.568,-0.155 -2.009,-0.243 -4.322,-0.243l-0.878,0.033c-0.607,0 -1.264,0.017 -1.981,0.055c-0.729,0.033 -1.204,0.083 -1.463,0.155c-0.497,0.138 -0.817,0.458 -0.955,0.955c-0.155,0.568 -0.243,1.457 -0.243,2.666l0.033,0.464c0,0.331 0.017,0.712 0.055,1.153c0.033,0.442 0.083,0.79 0.155,1.049c0.138,0.497 0.458,0.817 0.955,0.955c0.568,0.155 2.009,0.243 4.322,0.243l0.878,-0.033c0.607,-0 1.264,-0.017 1.981,-0.055c0.729,-0.033 1.204,-0.083 1.463,-0.155c0.497,-0.138 0.817,-0.458 0.955,-0.955c0.155,-0.568 0.243,-1.457 0.243,-2.666l-0.033,-0.464c-0,-0.331 -0.017,-0.712 -0.055,-1.153c-0.033,-0.442 -0.083,-0.79 -0.155,-1.049Zm-6.381,4.322l-0,-3.312l2.865,1.656l-2.865,1.656Z"
            fill="currentColor"
        />
    </svg>
);

YoutubeIcon.propTypes = propTypes;
YoutubeIcon.defaultProps = defaultProps;

export default React.memo(YoutubeIcon);
