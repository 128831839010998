import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const useTranslation = (scope, locale) => {
  const locales = useStaticQuery(graphql`
    query {
      en: allLocale(filter: {lang: {eq: "en"}}) {
        nodes {
          name
          content
        }
      }
      de: allLocale(filter: {lang: {eq: "de"}}) {
        nodes {
          name
          content
        }
      }
    }
  `);

  const findTranslations = (scope) => {
    return JSON.parse(locales[locale].nodes.find((l) => l.name === scope).content);
  }

  const t = (copy) => {
    let translations = findTranslations(scope);

    return translations[copy] ? translations[copy] : copy;
  };

  return { t };
};

export default useTranslation;
