import React from 'react';
import Link from '../Link';
import { useLayoutActions } from '../../layout/Context';

const NavButton = ({ children, to }) => {
  const { closeMenu } = useLayoutActions();

  return (
    <Link
      onClick={closeMenu}
      className="block group typo-intro antialiased select-none py-4 no-underline w-full relative text-left"
      href={to}
    >
      {children}
    </Link>
  );
};

export default NavButton;
