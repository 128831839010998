import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: 'h-6 w-6',
};

const LinkedInIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.68,0c4.239,0 7.68,3.441 7.68,7.68c0,4.239 -3.441,7.68 -7.68,7.68c-4.239,-0 -7.68,-3.441 -7.68,-7.68c0,-4.239 3.441,-7.68 7.68,-7.68Zm4.8,12.48l0,-3.823c0,-1.29 -1.061,-2.351 -2.351,-2.351c-0.613,-0 -1.327,0.375 -1.674,0.938l0,-0.801l-2.012,0l0,6.037l2.012,0l0,-3.556c0,-0.555 0.448,-1.01 1.003,-1.01c0.554,0 1.01,0.456 1.01,1.01l-0,3.556l2.012,0Zm-7.379,0l0,-6.037l-1.997,0l-0,6.037l1.997,0Zm-1.002,-7.169c0.665,-0 1.212,-0.547 1.212,-1.212c-0,-0.671 -0.541,-1.219 -1.212,-1.219c-0.669,0 -1.219,0.55 -1.219,1.219c-0,0.671 0.548,1.212 1.219,1.212Z"
            fill="currentColor"
        />
    </svg>
);

LinkedInIcon.propTypes = propTypes;
LinkedInIcon.defaultProps = defaultProps;

export default React.memo(LinkedInIcon);
