import classnames from 'classnames';
import React, { useEffect } from 'react';

import Link from '../Link';

import { useLayoutState } from '../../layout/Context';
import useNavigation from '../../hooks/useNavigation';

const LocaleNavigation = ({ location }) => {
  const { locale } = useLayoutState();
  const { getLink } = useNavigation();

  return (
    <div className="flex">
      <Link
        href={getLink(location.pathname, 'de')}
        className={classnames('typo-intro no-underline uppercase', {
          'text-indigo-500': locale === 'de',
          'hover:text-indigo-500': locale !== 'de',
        })}
      >
        De
      </Link>
      <span className="block bg-gray-600 w-px my-2 mx-2" />
      <Link
        href={getLink(location.pathname, 'en')}
        className={classnames('typo-intro no-underline uppercase', {
          'text-indigo-500': locale === 'en',
          'hover:text-indigo-500': locale !== 'en',
        })}
      >
        En
      </Link>
    </div>
  );
};

export default LocaleNavigation;
