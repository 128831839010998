import classnames from 'classnames';
import React from 'react';

import ChevronDownIcon from '../icons/ChevronDownIcon';

const ToggleIcon = ({ isActive, onClick }) => {
  return (
    <i className={classnames('bg-indigo-500 text-white rounded-sm block h-8 w-8 relative ',
      {
        'rotate-180': isActive,
      })}
      onClick={onClick}
    >
      <ChevronDownIcon className="h-8 w-8" />
    </i>
  );
};

export default ToggleIcon;
