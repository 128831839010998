import * as React from 'react';
import { LayoutProvider, LayoutConsumer } from './Context';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, location }) => {
  return (
    <LayoutProvider location={location}>
      <Header location={location} />
      <main className="pt-16 md:pt-24">
        {children}
      </main>
      <Footer />
    </LayoutProvider>
  );
};

export default Layout;
