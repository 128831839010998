import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: 'h-6 w-6',
};

const ChevronDownIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronDownIcon.propTypes = propTypes;
ChevronDownIcon.defaultProps = defaultProps;

export default React.memo(ChevronDownIcon);
