import classnames from 'classnames';
import React, { useState } from 'react';

import Button from '../components/Button';
import Link from '../components/Link';
import MenuButton from '../components/MenuButton';
import Nav from '../components/Nav';
import NavButton from '../components/NavButton';
import LocaleSwitch from '../components/LocaleSwitch';
import ToggleIcon from '../components/ToggleIcon';
import Submenu from '../components/Submenu';

import { useLayoutActions, useLayoutState } from './Context';

import LogoImageSrc from '../images/logo.png';
import ClockImageSrc from '../images/visuals/plusmeta-spart-zeit-mit-hilfe-von-ki.svg';

import useNavigation from '../hooks/useNavigation';
import useTranslation from '../hooks/useTranslation';

import whenKey from '../utils/whenKey';

const Header = ({ location }) => {
  const { menuOpen, locale } = useLayoutState();
  const { toggleMenu } = useLayoutActions();
  const { getLink } = useNavigation(locale);
  const { t } = useTranslation('navigation', locale);

  const [ activeNav, setActiveNav ] = useState(null);

  const toggleNav = (value) => {
    if (activeNav === value) {
      setActiveNav(null);
    } else {
      setActiveNav(value);
    }
  };

  const isActiveNav = (value) => {
    return activeNav === value;
  };

  return (
    <>
      <header className="fixed z-10 inset-x-0 top-0 bg-white bg-opacity-90">
        <div className="container-xl flex flex-wrap items-center justify-between w-full h-16 md:h-24">
          <div className="lg:w-1/4">
            <Link href={getLink('/')} className="block uppercase">
              <img src={LogoImageSrc} alt="Logo" className="h-10" />
            </Link>
          </div>
          <div className="lg:w-3/4">
            <Nav className="hidden lg:flex justify-between items-center">
              <Nav.Link to={getLink('/software/')} title={t('Software')}>
                <Nav.Dropdown>
                  {/*
                  <Submenu.Item to={getLink('/software/#features')}>
                    {t('Features')}
                  </Submenu.Item>
                  */}
                  <Nav.DropdownLink to={getLink('/software/#integrationen')}>
                    {t('Integrationen')}
                  </Nav.DropdownLink>
                  {/*
                  <Submenu.Item to={getLink('/software/#editionen')}>
                    {t('Editionen')}
                  </Submenu.Item>
                  */}
                  <Nav.DropdownLink to={getLink('/software/#software')}>
                    {t('Software')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/software/#plugins')}>
                    {t('Plugins')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/software/#opensource')}>
                    {t('Open Source')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/software/#datensicherheit')}>
                    {t('Datensicherheit')}
                  </Nav.DropdownLink>
                </Nav.Dropdown>
              </Nav.Link>
              <Nav.Link to={getLink('/loesungen/')} title={t('Lösungen')}>
                <Nav.Dropdown>
                  <Nav.DropdownLink to={getLink('/loesungen/#ueberblick')}>
                    {t('Überblick')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/loesungen/#plattform')}>
                    {t('Plattform')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/loesungen/#unser-vorgehen')}>
                    {t('Unser Vorgehen')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/loesungen/#standards')}>
                    {t('Standards')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/vdi2770/')}>
                    {t('VDI 2770')}
                  </Nav.DropdownLink>
                </Nav.Dropdown>
              </Nav.Link>
              <Nav.Link to={getLink('/branchen/')} title={t('Branchen')}>
                <Nav.Dropdown>
                  <Nav.DropdownLink to={getLink('/branchen/#success-stories')}>
                    {t('Success Stories')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/branchen/#kunden')}>
                    {t('Kunden')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/branchen/#branchen')}>
                    {t('Branchen')}
                  </Nav.DropdownLink>
                </Nav.Dropdown>
              </Nav.Link>
              <Nav.Link to={getLink('/unternehmen/')} title={t('Unternehmen')}>
                <Nav.Dropdown>
                  <Nav.DropdownLink to={getLink('/unternehmen/#ueber-uns')}>
                    {t('Über uns')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#team')}>
                    {t('Unser Team')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#karriere')}>
                    {t('Karriere')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#forschung')}>
                    {t('Forschung')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#mitgliedschaften')}>
                    {t('Mitgliedschaften')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#auszeichnungen')}>
                    {t('Auszeichnungen')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/unternehmen/#partner')}>
                    {t('Partner')}
                  </Nav.DropdownLink>
                </Nav.Dropdown>
              </Nav.Link>
              <Nav.Link to={getLink('/support/')} title={t('Support')}>
                <Nav.Dropdown>
                  <Nav.DropdownLink to={getLink('/support/#anwenderportal')}>
                    {t('Anwenderportal')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/support/#schulungen')}>
                    {t('Schulungen')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/support/#videos')}>
                    {t('plusmeta-Videos')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/support/#dokumentation')}>
                    {t('Dokumentation')}
                  </Nav.DropdownLink>
                  <Nav.DropdownLink to={getLink('/kontakt/')}>
                    {t('Kontakt')}
                  </Nav.DropdownLink>
                </Nav.Dropdown>
              </Nav.Link>
              <Nav.Link to={getLink('/veranstaltungen/')} title={t('Events')} />
              <Nav.Link to={getLink('/blog/')} title={t('Blog')} />
              <LocaleSwitch location={location} />
            </Nav>
            <div className="lg:hidden">
              <div className="flex gap-x-8 items-center">
                <LocaleSwitch location={location} />
                <MenuButton
                  onClick={toggleMenu}
                  isActive={menuOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={classnames('bg-black bg-opacity-30 fixed inset-0 z-10 transition-all duration-500', {
          'invisible opacity-0': !menuOpen,
          'visible opacity-100': menuOpen,
        })}
        onClick={() => { toggleMenu() }}
        onKeyDown={whenKey(' ', () => { })}
        role="presentation"
      />
      <div className={classnames('max-w-full w-full sm:w-96 h-full bg-white fixed z-20 transition-all duration-500',
        {
          'invisible opacity-0 -right-full': !menuOpen,
          'visible opacity-100 right-0': menuOpen,
        },
      )}
      >
        <div className="flex flex-col h-full">
          <div className="h-16 md:h-24 px-4 md:px-8 flex justify-between items-center">
            <Link href={getLink('/')} className="block uppercase" onClick={toggleMenu}>
              <img src={LogoImageSrc} alt="Logo" className="h-8" />
            </Link>
            <div className="flex gap-x-8 items-center">
              <LocaleSwitch location={location} />
              <MenuButton
                onClick={toggleMenu}
                isActive={menuOpen}
              />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            <ul className="py-8 divide-y px-4 md:px-8">
              <li>
                <div className="flex items-center">
                  <NavButton to={getLink('/software/')}>
                    {t('Software')}
                  </NavButton>
                  <Button onClick={() => { toggleNav(0) }}>
                    <ToggleIcon isActive={isActiveNav(0)} />
                  </Button>
                </div>
                <Submenu isActive={isActiveNav(0)}>
                  {/*
                  <Submenu.Item to={getLink('/software/#features')}>
                    {t('Features')}
                  </Submenu.Item>
                  */}
                  <Submenu.Item to={getLink('/software/#integrationen')}>
                    {t('Integrationen')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/software/#software')}>
                    {t('Software')}
                  </Submenu.Item>
                  {/*
                  <Submenu.Item to={getLink('/software/#editionen')}>
                    {t('Editionen')}
                  </Submenu.Item>
                  */}
                  <Submenu.Item to={getLink('/software/#plugins')}>
                    {t('Plugins')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/software/#opensource')}>
                    {t('Open Source')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/software/#datensicherheit')}>
                    {t('Datensicherheit')}
                  </Submenu.Item>
                </Submenu>
              </li>
              <li>
                <div className="flex items-center">
                  <NavButton to={getLink('/loesungen/')}>
                    {t('Lösungen')}
                  </NavButton>
                  <Button onClick={() => { toggleNav(1) }}>
                    <ToggleIcon isActive={isActiveNav(1)} />
                  </Button>
                </div>
                <Submenu isActive={isActiveNav(1)}>
                  <Submenu.Item to={getLink('/loesungen/#ueberblick')}>
                    {t('Überblick')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/loesungen/#unser-vorgehen')}>
                    {t('Unser Vorgehen')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/loesungen/#standards')}>
                    {t('Standards')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/vdi2770/')}>
                    {t('VDI 2770')}
                  </Submenu.Item>
                </Submenu>
              </li>
              <li>
                <div className="flex items-center">
                  <NavButton to={getLink('/branchen/')}>
                    {t('Branchen')}
                  </NavButton>
                  <Button onClick={() => { toggleNav(2) }}>
                    <ToggleIcon isActive={isActiveNav(2)} />
                  </Button>
                </div>
                <Submenu isActive={isActiveNav(2)}>
                  <Submenu.Item to={getLink('/branchen/#successstories')}>
                    {t('Success Stories')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/branchen/#kunden')}>
                    {t('Kunden')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/branchen/#branchen')}>
                    {t('Branchen')}
                  </Submenu.Item>
                </Submenu>
              </li>
              <li>
                <div className="flex items-center">
                  <NavButton to={getLink('/unternehmen/')}>
                    {t('Unternehmen')}
                  </NavButton>
                  <Button onClick={() => { toggleNav(3) }}>
                    <ToggleIcon isActive={isActiveNav(3)} />
                  </Button>
                </div>
                <Submenu isActive={isActiveNav(3)}>
                  <Submenu.Item to={getLink('/unternehmen/#ueber-uns')}>
                    {t('Über uns')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#team')}>
                    {t('Unser Team')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#karriere')}>
                    {t('Karriere')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#forschung')}>
                    {t('Forschung')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#mitgliedschaften')}>
                    {t('Mitgliedschaften')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#auszeichnungen')}>
                    {t('Auszeichnungen')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/unternehmen/#partner')}>
                    {t('Partner')}
                  </Submenu.Item>
                </Submenu>
              </li>
              <li>
                <div className="flex items-center">
                  <NavButton to={getLink('/support/')}>
                    {t('Support')}
                  </NavButton>
                  <Button onClick={() => { toggleNav(4) }}>
                    <ToggleIcon isActive={isActiveNav(4)} />
                  </Button>
                </div>
                <Submenu isActive={isActiveNav(4)}>
                  <Submenu.Item to={getLink('/support/#anwenderportal')}>
                    {t('Anwenderportal')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/support/#schulungen')}>
                    {t('Schulungen')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/support/#plusmetavideos')}>
                    {t('plusmeta-Videos')}
                  </Submenu.Item>
                  <Submenu.Item to={getLink('/support/#dokumentation')}>
                    {t('Dokumentation')}
                  </Submenu.Item>
                </Submenu>
              </li>
              <li>
                <NavButton to={getLink('/veranstaltungen/')}>
                  {t('Events')}
                </NavButton>
              </li>
              <li>
                <NavButton to={getLink('/blog/')}>
                  {t('Blog')}
                </NavButton>
              </li>
            </ul>
            <div className="bg-indigo-500 text-white py-8 px-4 md:px-8 mt-16">
              <img src={ClockImageSrc} className="-mt-40" alt="Mit der KI von plusmeta bis zu 80 % Zeitersparnis!" />
              <p className="typo-h5 text-yellow-700 text-center mt-4">
                {t('Banner title')}
              </p>
              <div className="mt-4 text-center">
                <Button to="/" variant="secondary" size="md" theme="alt">
                  {t('Banner action')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
