import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';

import NavButton from '../NavButton';

const groupVariants = {
  enter: { opacity: 1 },
  idle: { opacity: 1, transition: { staggerChildren: 0.07 } },
  exit: { opacity: 0 },
};

const itemVariants = {
    enter: { opacity: 0, x: '5%' },
    idle: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
};

const Submenu = ({ children, isActive }) => {
  const items = React.Children.toArray(children);

  return (
    <AnimatePresence>
      {isActive && (
        <motion.ul
          className="pl-4 divide-y"
          variants={groupVariants}
          initial="enter"
          animate="idle"
          exit="exit"
        >
          {items.map((item, i) => (
            <motion.li
              key={i}
              variants={itemVariants}
            >
              {item}
            </motion.li>
          ))}
        </motion.ul>
      )}
    </AnimatePresence>
  );
};

const Item = ({ children, to }) => {
  return (
    <NavButton to={to}>
      {children}
    </NavButton>
  );
};

Submenu.Item = Item;

export default Submenu;
